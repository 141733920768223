<template>
  <div id="app">
    <UploadFile />
    <router-view></router-view>
  </div>
</template>

<script>
// import {globalData} from "@/hooks/globalData.js";
import UploadFile from '../components/UploadFile/index.vue'
export default {
  name: "App",
  components: {
    UploadFile
  },
  data() {
    return {};
  },
  created() {
    this.initApp()
  },
  methods: {
    // 获取frontend.conf.json文件数据，写入到window中
    initApp() {
      let config = {};
      const oAjax = new XMLHttpRequest();
      oAjax.open('GET', `/attachments/frontend.conf.json`, false); // false表示同步请求
      oAjax.onreadystatechange = () => {
      if (oAjax.readyState === 4 && oAjax.status === 200) {
          config = JSON.parse(oAjax.responseText);
          // 将全局变量存入window
          Object.entries(config).forEach(([key, value]) => {
            window[key] = value;
          });
        }
      };
      oAjax.send();
    }
  },
};
</script>

<style lang="less">
@import "../assets/css/base.css";
@import "../assets/css/content.less";
</style>
