import axios from "axios";
import SparkMD5 from 'spark-md5';

export default function(file, progress, suffix) {
  progress(0)
    return new Promise(async (resolve) => {
      let {fileHash, chunkList} = await getMD5(file, 5 * 1024 * 1024);
      let checkInfo = await checkFileMd5(fileHash);
      let step = 100 / chunkList.length;
      let data = {}
      // 未上传过文件 ，直接上传
      if (checkInfo.code == 101) {
          data = await sliceUpload(fileHash, chunkList, progress, 0, step);
      } else if (checkInfo.code == 100) {
          data = await uploadSuccess(checkInfo, progress)
      } else if (checkInfo.code == 102) {
          let list = chunkList.slice(checkInfo.data.fileName);
          data = await sliceUpload(fileHash, list, progress, step * Number(checkInfo.data.fileName), step);
      }
      resolve(data)
    })
}
async function uploadSuccess(checkInfo, progress) {
  progress(100)
  return checkInfo
}
// 切片上传
function sliceUpload(fileHash, chunkList, progress, currentProgress, step) {
    return new Promise(async (resolve) => {
      let error = null;
      while (chunkList.length > 0) {
        let chunk = chunkList.splice(0, 1);
        let [err] = await uploadChunk(fileHash, chunk[0]);
        if (err) {
          error = err;
          break;
        }
        currentProgress += step
        progress(currentProgress)
      }

      // 切片上传完成之后轮询查询上传结果;
      if (error) {
        resolve('上传失败')
      }
      let checkInfo = await pollingGetResult(fileHash);
      if (checkInfo.code == 100) {
        progress(100)
      }
      resolve(checkInfo)
    })
}
// 轮询获取结果
async function pollingGetResult(fileHash) {
  return new Promise(async (resolve) => {
    let timeEnd = 0;
    let checkInfo = {}
    while (timeEnd < 1000 * 60 * 10) {
      await new Promise((resolve) => {
        setTimeout(async () => {
          timeEnd += 3000;
          checkInfo = await checkFileMd5(fileHash);
          // 轮询结束条件
          if (checkInfo.code == 100) {
            timeEnd = 1000 * 60 * 10;
          }
          resolve();
        }, 3000);
      });
    }
    resolve(checkInfo)
  })
}
// 上传单个chunk
async function uploadChunk(fileHash, chunk) {
    let formdata = new FormData();
    const token = localStorage.getItem("Authorization");
    formdata.append('md5', fileHash);
    formdata.append('chunkSize', 5 * 1024 * 1024);
    formdata.append('name', chunk.name);
    formdata.append('type', chunk.type);
    formdata.append('size', chunk.size);
    formdata.append('chunks', chunk.chunks);
    formdata.append('chunk', chunk.chunk);
    formdata.append('token', token);
    formdata.append('file', chunk.file);
    let { data } = await axios({
        url: 'https://gildata.wedengta.com/file/sliceUpload',
        method: "post",
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return [data.err, data.data];
}
// 校验md5文件
async function checkFileMd5(md5) {
    let formdata = new FormData();
    formdata.append('md5', md5);
    let { data } = await axios({
        url: 'https://gildata.wedengta.com/file/checkFileMd5',
        method: "post",
        data: formdata,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return data;
}

  // 根据文件生成md5
function getMD5(file, DefualtChunkSize, cb) {
    return new Promise((resolve, reject) => {
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      let chunks = Math.ceil(file.size / DefualtChunkSize);
      let currentChunk = 0;
      let chunkList = [];
      let spark = new SparkMD5.ArrayBuffer();
      let fileReader = new FileReader();

      fileReader.onload = function (e) {
        const chunk = e.target.result;
        spark.append(chunk);
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          let fileHash = spark.end();
          // cb && cb(fileHash);
          resolve({
            fileHash,
            chunkList
          });
        }
      };

      fileReader.onerror = function () {
        console.warn('生成MD5失败');
        reject({});
      };

      function loadNext() {
        let start = currentChunk * DefualtChunkSize,
          end = start + DefualtChunkSize >= file.size ? file.size : start + DefualtChunkSize;

        let chunk = blobSlice.call(file, start, end);
        chunkList.push({
          file: chunk,
          name: file.name,
          type: file.type,
          size: file.size,
          chunk: currentChunk,
          chunks: chunks
        });
        fileReader.readAsArrayBuffer(chunk);
      }

      loadNext();
    });
}