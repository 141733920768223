import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store/index'



//引入videojs的皮肤
import "video.js/dist/video-js.min.css";

//引入element，主要为了pc端后台服务
import "./plugins/element-ui";

//引入vant，主要为videoPlayer服务
import "./plugins/vant";

//引入vueOnTouch，为了实现double tap
import vOnTouch from './plugins/v-on-touch.js';
vOnTouch.install(Vue)

// 引入VueCropper，为了实现截图上传
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
// import vueFinger from './plugins/vue-finger.js';
// vueFinger.install(Vue)
// 映入echarts

import VueScrollmagic from 'vue-scrollmagic'
Vue.use(VueScrollmagic)

import * as echarts from 'echarts'
//引入animate css
import '../assets/css/animate.css'

Vue.config.productionTip = false;

import API from "@/api/api";
Vue.prototype.API = API;

import axios from "axios";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import { newPage } from "@/utils/utils.js";

Vue.prototype.axios = axios;
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment;
Vue.prototype.$toNewPage = newPage;

//创建全局 event bus
var EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus;
    }
  }
});

//自定义皮肤
import "./theme/index.css";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");