/* * @Author: liuhanchuan * @Date: 2021-05-24 11:20:25 * @Last Modified by: mask.fang * @Last Modified time: 2021-11-17
13:46:34 */

<template>
  <el-card
    :class="['box-card', dialogVisible ? 'expand' : 'noexpand']"
    :style="{ opacity: visible ? 1 : 0, height: visible ? '' : '0px !important' }"
  >
    <div slot="header" class="clearfix">
      <div class="loading">
        <img src="@/assets/images/loading.svg" :class="[loading ? 'animation' : '']" />
        <span>{{ loading ? "上传中" : "任务已完成" }}</span>
      </div>
      <div class="operation">
        <i
          :class="[dialogVisible ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"
          style="marginright: 8px"
          @click="changeAddFile"
        />
        <i class="el-icon-close" @click="closeAddFile" />
      </div>
    </div>
    <div :class="['content_list', dialogVisible ? '' : 'hidden_list']">
      <el-upload
        ref="upload"
        action="#"
        multiple
        :accept="accept"
        :file-list="fileList"
        :http-request="upLoad"
        :before-remove="beforeRemove"
        :before-upload="beforeAvatarUpload"
      />
    </div>
  </el-card>
</template>
<script>
import { uploadFile } from "@/api/upload";
import sliceUploadVideo from '@/api/slice-upload';
export default {
  data: function () {
    return {
      fileList: [], // 上传文件列表
      dialogVisible: true, // 展开收起
      loading: false, // 控制组件上传状态
      visible: false, // 控制组件显示隐藏
      uploadFileNum: 0, // 通过验证，实际上传数
      apiFileNum: 0, // 调用上传接口数
      uploadFileType: "video", // 上传文件格式
      from: "", //谁在调用
      successData: [], //上传成功后的数据
    };
  },
  computed: {
    acceptType: function () {
      switch (this.uploadFileType) {
        case "image":
          return "图片";
          break;
        // 音频只支持amr格式的
        case "audio":
          return "音频";
          break;
        case "video":
          return "视频";
          break;
        case "file":
          return "文件";
          break;
        default:
          return "*";
          break;
      }
    },
    accept: function () {
      switch (this.uploadFileType) {
        case "image":
          return "image/*";
          break;
        // 音频只支持amr格式的
        case "audio":
          return "audio/*";
          break;
        case "video":
          return "video/m4v, video/mp4, video/webm, video/ogg";
          break;
        case "file":
          return ".docx, .pptx, .xlsx, .doc, .pdf, .xls, .ppt";
          break;
        default:
          return "*";
          break;
      }
    },
    acceptSize: function () {
      switch (this.uploadFileType) {
        case "image":
          return 10;
          break;
        // 音频只支持amr格式的
        case "audio":
          return 10;
          break;
        case "video":
          return 200;
        case "file":
          return 10;
        default:
          return 10;
          break;
      }
    },
  },
  watch: {
    apiFileNum() {
      if (this.apiFileNum && this.uploadFileNum && this.apiFileNum === this.uploadFileNum) {
        this.loading = false;
        if (this.from == "videoList") {
          this.$bus.$emit("upDataList", this.from, this.successData);
          this.successData = []
        } else {
          this.$bus.$emit("uploadSuccess", this.from, this.successData);
          this.successData = []
        }
      }
    },
  },
  mounted() {
    this.$bus.$on("openUploadFile", (settings) => {
      this.uploadFileType = settings.type;
      this.from = settings.from;
      this.$nextTick(() => {
        this.$refs["upload"].$refs["upload-inner"].handleClick();
      });
    });
  },
  methods: {
    // 展开/收起添加文件列表
    changeAddFile() {
      this.dialogVisible = !this.dialogVisible;
    },
    // 关闭文件列表
    closeAddFile() {
      this.fileList = [];
      this.successData = []
      this.uploadFileNum = 0;
      this.apiFileNum = 0;
      this.dialogVisible = true;
      this.visible = false;
    },
    beforeAvatarUpload(file) {
      const isLtM = file.size / 1024 / 1024 < this.acceptSize;
      let isType = null;
      // 判断音频
      if (this.uploadFileType === "audio") {
        if ((file.name.indexOf(".amr") !== -1 && file.type.length === 0) || file.type.indexOf("audio/") !== -1) {
          isType = true;
        } else {
          isType = false;
        }
      }
      // 判断图片
      if (this.uploadFileType === "image") {
        if (file.type.indexOf("image/") !== -1) {
          isType = true;
        } else {
          isType = false;
        }
      }
      // 判断视频
      if (this.uploadFileType === "video") {
        if (["video/m4v", "video/mp4", "video/ogg", "video/webm"].indexOf(file.type) !== -1) {
          isType = true;
        } else {
          isType = false;
        }
      }
      // 判断文件
      if (this.uploadFileType === "file") {
        if (
          [".docx", ".pptx", ".xlsx", ".doc", ".pdf", ".xls", ".ppt"].filter((item) => file.name.indexOf(item) !== -1)
            .length > 0
        ) {
          isType = true;
        } else {
          isType = false;
        }
      }
      if (!isType) {
        this.$message.error(`${this.acceptType}格式错误，不支持此格式文件`);
      } else if (!isLtM) {
        this.$message.error(`上传资源大小不能超过 ${this.acceptSize}MB!`);
      } else {
        this.uploadFileNum += 1;
      }
      return isLtM && isType;
    },
    // 上传文件
    async upLoadFile(params) {
      const suffix = this.getSuffix(params.file);
      let progress = function (progressEvent) {
        if (progressEvent instanceof Object && progressEvent.lengthComputable) {
          let percent = (progressEvent.loaded / progressEvent.total) * 100;
          params.onProgress({ percent: percent });
        } else {
          params.onProgress({ percent: progressEvent });
        }
      };
      let data;
      //目前只有从视频列表过来的上传直接进入解析，其余的不解析
      if (this.from == "videoList") {
        // 旧的视频上传，带语音识别一起
        // data = await uploadVideo(params.file, progress, suffix);
        data = await sliceUploadVideo(params.file, progress, suffix);
      } else {
        data = await uploadFile(params.file, progress, suffix);
      }
      if (data instanceof Object && (data.code === 200 || data.code === 100)) {
        params.onSuccess(data);
        this.apiFileNum += 1;
        if (data.code === 100) {
          this.successData.push(data.data)
        } else {
          this.successData.push(data.data[0])
        }
      } else {
        this.apiFileNum += 1;
        params.onError(data)
        // 当视频解析次数用完的时候
        if (data instanceof Object && data.code === 408) {
          this.$bus.$emit('connectUs')
        }
      }
    },
    // 当选择多个文件时，调用多次
    upLoad(file) {
      if (!this.loading) {
        this.loading = true;
      }
      if (!this.visible) {
        this.visible = true;
      }
      this.upLoadFile(file);
    },
    beforeRemove(file, fileList) {
      if (file.status === "uploading") {
        this.$message.warning(`${file.name}正在上传中`);
        return false;
      }
      return true;
    },
    //获得文件后缀名
    getSuffix(file) {
      const suffix = file.name.toLowerCase().split(".").splice(-1);

      return suffix[0];
    },
  },
};
</script>
<style scoped lang="less">
.expand {
  height: 360px !important;
  line-height: 1.2;
}
.noexpand {
  height: 74px !important;
}
.box-card {
  overflow: hidden;
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 20px;
  width: 540px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgb(15 32 66 / 20%);
  background-color: #fff;
  border: 1px solid rgba(5, 13, 27, 0.16);
  overflow: hidden;
  transition: height 0.3s ease;
  .clearfix {
    display: flex;
    justify-content: space-between;
    .loading {
      display: flex;
      align-items: center;
      > img {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        transform: rotate(90deg);
      }
      .animation {
        animation: identifier 1s linear infinite;
      }
      @keyframes identifier {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      > span {
        font-size: 24px;
        color: #000;
      }
    }
    .operation {
      > i {
        font-size: 24px;
        cursor: pointer;
        &:hover {
          color: #3472e8;
        }
      }
    }
    &::after {
      display: none;
    }
  }
}
/deep/ .el-card__body {
  padding-top: 0px;
  .el-upload-list {
    height: 240px;
    overflow-y: auto;
    margin-top: -16px;
  }
}
</style>
